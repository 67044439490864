import React from "react"
import Imgix from "react-imgix"
import styles from "./stylesheets/fullWidthImage.module.scss"

export default function FullWidthImage({ src, alt }) {
  return (
    <Imgix
      sizes="100vw"
      src={src}
      alt={alt}
      className={styles.fullWidthImage}
    />
  )
}
