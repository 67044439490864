import React from "react"
import { Modal, Button } from "react-bootstrap"
import ContactBaseFields from "./contactBaseFields"
import Recaptcha from "react-recaptcha"
import { siteKey } from "../utils/recaptcha"
import styles from "./stylesheets/contactModal.module.scss"
import cx from "classnames"

export default function ContactModal({
  show,
  setShow,
  hideAdvanced,
  hideCompany,
  hideNumberOfVehicles,
  leadSource,
}) {
  const [canSubmit, setCanSubmit] = React.useState(false)
  const handleClose = () => setShow(false)
  const path = "/api/submit_salesforce"

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className="border-0 pt-4 px-0 mx-4 mx-md-5">
          <div className="d-flex flex-column">
            <Modal.Title className={cx("mb-3", styles.title)}>
              Get in touch today!
            </Modal.Title>
            <h5 className={styles.subtitle}>
              Fill out the form below to get a quote.
            </h5>
          </div>
        </Modal.Header>
        <form action={path} method="POST">
          <Modal.Body className="pb-3 px-4 px-md-5">
            <ContactBaseFields
              leadSource={leadSource || "no-lead-source"}
              hideAdvanced={hideAdvanced}
              hideCompany={hideCompany}
              hideNumberOfVehicles={hideNumberOfVehicles}
            />
          </Modal.Body>

          <div className="mx-4 mx-md-5 my-3">
            <Recaptcha
              sitekey={siteKey}
              render="explicit"
              verifyCallback={() => setCanSubmit(true)}
            />
          </div>

          <div className="border-0 pt-0 pb-4 px-0 px-md-5">
            <div className="d-flex flex-column flex-md-row justify-content-around">
              <input
                type="submit"
                name="submit"
                disabled={!canSubmit}
                className={cx(
                  styles.submit,
                  "btn btn-lg btn-success mb-3 mx-4 ml-md-0",
                )}
              />
              <Button
                variant="secondary"
                className={cx(styles.cancel, "mb-3 mx-4 mx-md-0")}
                onClick={handleClose}
                size="lg"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}
