export const standardizePrismicType = (data) => {
  const { __typename } = data

  return {
    ...data,
    __typename: __typename.startsWith("PRISMIC_")
      ? __typename
      : `PRISMIC_${__typename}`,
  }
}
