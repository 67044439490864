import React from "react"
import { Form } from "react-bootstrap"
import styles from "./stylesheets/contactBaseFields.module.scss"
import { states } from "../utils/states"
import cx from "classnames"

export default function ContactBaseFields({
  leadSource,
  hideAdvanced,
  hideNumberOfVehicles,
  hideCompany,
  optionalEmail,
}) {
  const [inquiryType, setInquiryType] = React.useState("")
  if (typeof window === `undefined`) return null
  const requestQuote = inquiryType === "Request a Quote" ? "true" : "false"
  const redirectUrl = `${window.location.href}?submit=true&request_quote=${requestQuote}`

  return (
    <div>
      <input type="hidden" name="oid" value="00D60000000ITqi" />
      <input
        type="hidden"
        name="recordType"
        id="recordType"
        value="012320000001FoX"
      />
      <input type="hidden" name="retURL" value={redirectUrl} />
      <input type="hidden" name="lead_source" value={leadSource} />
      <input type="hidden" name="00N0e000003bENe" value={inquiryType} />

      <Form.Group>
        <Form.Label className={styles.formLabel}>
          First Name
          <span className={styles.requiredAsterisk}>*</span>
        </Form.Label>
        <input
          id="first_name"
          maxLength="40"
          name="first_name"
          size="20"
          type="text"
          className={cx(styles.formInput, "form-control form-control-lg")}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className={styles.formLabel}>
          Last Name
          <span className={styles.requiredAsterisk}>*</span>
        </Form.Label>
        <input
          id="last_name"
          maxLength="80"
          name="last_name"
          size="20"
          type="text"
          className={cx(styles.formInput, "form-control form-control-lg")}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className={styles.formLabel}>
          Email Address
          {!optionalEmail && <span className={styles.requiredAsterisk}>*</span>}
        </Form.Label>
        <input
          id="email"
          maxLength="80"
          name="email"
          size="20"
          type="text"
          className={cx(styles.formInput, "form-control form-control-lg")}
          required={!optionalEmail}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className={styles.formLabel}>
          Phone Number
          <span className={styles.requiredAsterisk}>*</span>
        </Form.Label>
        <input
          id="phone"
          maxLength="40"
          name="phone"
          size="20"
          type="text"
          className={cx(styles.formInput, "form-control form-control-lg")}
          required
        />
      </Form.Group>

      {!hideCompany && (
        <Form.Group>
          <Form.Label className={styles.formLabel}>
            Company
            <span className={styles.requiredAsterisk}>*</span>
          </Form.Label>
          <input
            id="company"
            maxLength="40"
            name="company"
            size="20"
            type="text"
            className={cx(styles.formInput, "form-control form-control-lg")}
            required
          />
        </Form.Group>
      )}

      {!hideNumberOfVehicles && (
        <Form.Group>
          <Form.Label className={styles.formLabel}>
            Number of Vehicles to Move
          </Form.Label>
          <input
            id="00N0e000003bAJO"
            name="00N0e000003bAJO"
            size="20"
            type="text"
            className={cx(styles.formInput, "form-control form-control-lg")}
          />
        </Form.Group>
      )}

      {!hideAdvanced && (
        <div>
          <div className="d-flex flex-row justify-content-between">
            <Form.Group className="w-50 pr-3">
              <Form.Label className={styles.formLabel}>Origin City</Form.Label>
              <input
                id="00N60000002Iral"
                maxLength="30"
                name="00N60000002Iral"
                size="20"
                type="text"
                className={cx(styles.formInput, "form-control form-control-lg")}
              />
            </Form.Group>

            <Form.Group className="w-50 pr-3">
              <Form.Label className={styles.formLabel}>Origin State</Form.Label>
              <select
                id="00N60000002Iraq"
                name="00N60000002Iraq"
                className={cx(styles.formInput, "form-control form-control-lg")}
              >
                {states.map((stateAbbr) => {
                  return (
                    <option value={stateAbbr} key={stateAbbr}>
                      {stateAbbr}
                    </option>
                  )
                })}
              </select>
            </Form.Group>

            <Form.Group className="w-50">
              <Form.Label className={styles.formLabel}>Origin ZIP</Form.Label>
              <input
                id="00N60000002Irav"
                maxLength="5"
                name="00N60000002Irav"
                size="20"
                type="text"
                className={cx(styles.formInput, "form-control form-control-lg")}
              />
            </Form.Group>
          </div>

          <div className="d-flex flex-row justify-content-between">
            <Form.Group className="w-50 pr-3">
              <Form.Label className={styles.formLabel}>
                Destination City
              </Form.Label>
              <input
                id="00N60000002Irb0"
                maxLength="30"
                name="00N60000002Irb0"
                size="20"
                type="text"
                className={cx(styles.formInput, "form-control form-control-lg")}
              />
            </Form.Group>

            <Form.Group className="w-50 pr-3">
              <Form.Label className={styles.formLabel}>
                Destination State
              </Form.Label>
              <select
                id="00N60000002Iraw"
                name="00N60000002Iraw"
                className={cx(styles.formInput, "form-control form-control-lg")}
              >
                {states.map((stateAbbr) => {
                  return (
                    <option value={stateAbbr} key={stateAbbr}>
                      {stateAbbr}
                    </option>
                  )
                })}
              </select>
            </Form.Group>

            <Form.Group className="w-50">
              <Form.Label className={styles.formLabel}>
                Destination ZIP
              </Form.Label>
              <input
                id="00N60000002Irb5"
                maxLength="5"
                name="00N60000002Irb5"
                size="20"
                type="text"
                className={cx(styles.formInput, "form-control form-control-lg")}
              />
            </Form.Group>
          </div>

          <Form.Group>
            <Form.Label className={styles.formLabel}>Vehicle Year</Form.Label>
            <input
              id="00N60000002IrbA"
              maxLength="4"
              name="00N60000002IrbA"
              size="20"
              type="text"
              className={cx(styles.formInput, "form-control form-control-lg")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className={styles.formLabel}>Vehicle Make</Form.Label>
            <input
              id="00N60000002IrbF"
              maxLength="30"
              name="00N60000002IrbF"
              size="20"
              type="text"
              className={cx(styles.formInput, "form-control form-control-lg")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className={styles.formLabel}>Vehicle Model</Form.Label>
            <input
              id="00N60000002IrbK"
              maxLength="255"
              name="00N60000002IrbK"
              size="20"
              type="text"
              className={cx(styles.formInput, "form-control form-control-lg")}
            />
          </Form.Group>
        </div>
      )}

      <Form.Group>
        <Form.Label className={cx(styles.formLabel, "mb-2")}>
          Reason for Inquiry
          <span className={styles.requiredAsterisk}>*</span>
        </Form.Label>
        <div className="d-flex">
          <Form.Check
            className={cx(styles.checkbox, "mb-1 mr-4")}
            type="radio"
            label="General"
            value={"General"}
            checked={inquiryType === "General"}
            name="inquiryType"
            required
            onClick={(e) => setInquiryType(e.target.value)}
          />
          <Form.Check
            className={styles.checkbox}
            type="radio"
            label="Request a Quote"
            value={"Request a Quote"}
            checked={inquiryType === "Request a Quote"}
            name="inquiryType"
            onClick={(e) => setInquiryType(e.target.value)}
          />
        </div>
      </Form.Group>

      <Form.Group className="mb-1">
        <Form.Label className={styles.formLabel}>
          Additional Information
        </Form.Label>
        <textarea
          id="00N60000002JTCpEAO"
          maxLength="255"
          name="00N60000002JTCpEAO"
          rows="8"
          type="text"
          wrap="soft"
          className={cx(styles.formInput, "form-control form-control-lg")}
        />
      </Form.Group>

      <div className="pt-3">*Required</div>
    </div>
  )
}
